export const mercury = '#e6e6e6';
export const tana = '#dad4bb';
export const softAmber = '#cdc8b0';
export const taupeGray = '#b4af9a';
export const fuscousGray = '#4e4b42';

export const ironsideGray = '#6c6b62';
export const athsSpecial = '#ece7d3';
export const hampton = '#e6ddaf';
export const indianKhaki = '#c0b496';
export const mongoose = '#bc9d83';
export const makara = '#8b7e67';

export const chestnutRose = '#cd664d';
